@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
    border-top-color: rgba(0, 0, 0, 0.5);
  }
  50% {
    transform: rotate(180deg);
    border-top-color: rgba(0, 0, 255, 0.5);
  }
  100% {
    transform: rotate(360deg);
    border-top-color: rgba(0, 0, 0, 0.5);
  }
}
* {
  box-sizing: border-box;
}

/* body {
  background: transparent;
  padding-bottom: 100px;
} */

/* h1,
h3 {
  display: block;
  margin: 0px auto;
  text-align: center;
  font-family: "Tahoma";
  font-weight: lighter;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 1.5px;
}

h1 {
  margin: 50px auto;
} */

.loader {
  position: relative;
  margin: 75px auto;
  width: 150px;
  height: 150px;
  display: block;
  overflow: hidden;
}
.loader div {
  height: 100%;
}

/* loader 1 */
.loader1,
.loader1 div {
  border-radius: 50%;
  padding: 8px;
  border: 2px solid transparent;
  animation: rotate linear 3.5s infinite;
  border-top-color: rgba(0, 0, 0, 0.5);
  border-bottom-color: rgba(0, 0, 255, 0.5);
}

div:hover {
  animation-play-state: paused;
}

.loader,
.loader * {
  will-change: transform;
}

.loadWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  opacity: 0.65;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999;
  padding-top: 15%;
}

.dashboardBcg {
  opacity: 1;
  background: white;
  padding-top: 15%;
}
