@tailwind base;
@tailwind components;
@tailwind utilities;

// Google fonts
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

  *{
    font-family: 'DM Sans', 'sans-serif';
    box-sizing: border-box;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  body{
    background-image: url(../svgs/hexagon-inverse.svg);
    background-repeat: no-repeat;
    background-position-x: -25rem;
    background-size: 100%;
    background-position-y: -15rem;
    transition: all 300ms ease;
    
    
  }

  html{
    scroll-behavior: smooth;
  }


// Color Styles
:root{
    --primary-deep : #624B93;
    --primary-dark : #7A58C1;
    --primary-500 : #8A6CC8; 
    --primary-400 : #A189D3;
    --primary-300 : #B9A7DE;
    --primary-200 : #D0C4E9;
    --primary-100 : #E8E2F4;

    --secondary-500 : #212121;
    --secondary-400 : #333333;
    --secondary-300 : #666666;
    --secondary-200 : #999999;
    --secondary-100 : #CCCCCC;

    --heading-one : 76.3px;
    --heading-two : 48.8px;
    --heading-three : 39.1px;
    --heading-four : 31.3px;
    --heading-five : 25px;
    --heading-six : 20px;
    --base-text : 14px;
    --small-text : 12.8px;
    --label-text : 10.2px;
    --tiny-text : 8.2px;

}

// Color Styles

.primary-500{
    color: var(--primary-500);
}

.primary-400{
    color: var(--primary-400);
}

.primary-300{
    color: var(--primary-300);
}

.primary-200{
    color: var(--primary-200);
}

.primary-100{
    color: var(--primary-100);
}

.primary-dark{
    color: var(--primary-dark);
}


.secondary-500{
    color: var(--secondary-500);
}

.secondary-400{
    color: var(--secondary-400);
}

.secondary-300{
    color: var(--secondary-300);
}

.secondary-200{
    color: var(--secondary-200);
}

.secondary-100{
    color: var(--secondary-100);
}

.text-red{
    color: red;
}


// Text Styles

.heading-one{
    font-size: var(--heading-one);
    font-weight: 500;
}

.heading-two{
    font-size: var(--heading-two);
    font-weight: 500;
}

.heading-three{
    font-size: var(--heading-three);
    font-weight: 700;
}

.heading-four{
    font-size: var(--heading-four);
    font-weight: 500;
}

.heading-five{
    font-size: var(--heading-five);
    font-weight: 500;
}

.heading-six{
    font-size: var(--heading-six);
    font-weight: 500;
}

.base-text-m{
    font-size: var(--base-text);
    font-weight: 400;
}

.base-text{
    font-size: var(--base-text);
}

.small-text{
    font-size: var(--small-text);
    font-weight: 400;
}

.text-white .top-bar-links a.text-link{
    color: #ffffff;
}

a.text-link{
    transition: all 200ms ease-in;
    color: var(--secondary-300);
}

a.text-link:hover{
    color: var(--primary-500) !important;
}



nav{
    padding: 12px 32px;
}

// button styles

.btn{
    padding: 12px 24px;
    font-size: var(--base-text);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in;
}

.btn-lg{
    padding: 12px 44px;
}

.btn-primary{
    background-color: var(--primary-500);
    color: #ffffff;
    border-bottom: 1.5px solid #7D5CC2;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    transition: all 200ms ease-in;
}

.btn-primary:hover{
    background-color: var(--primary-dark);
    border-bottom: 1.5px solid var(--primary-deep);
}

.btn-primary:focus{
    border: none;
    box-shadow: none;
}

.btn-secondary{
    background-color: #ffffff;
    color: var(--secondary-300) !important;
    border: 1.5px solid #e5e5e5;
    padding: 12px 32px;
    align-self: center;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
}

.btn-alt{
    background-color: #2C2B30;
    color: #ffffff;
    padding: 12px 32px;
    border:  1px solid var(--secondary-200);
    // box-shadow: 0px 0px 12px 1px rgba(255, 255, 255, 0.50), 0px -4px 4px 0px rgba(115, 115, 115, 0.25) inset, 0px 1px 1px 0px rgba(0, 0, 0, 0.03);

    &:hover{
        // background-color: rgb(27, 26, 30);
        background-color: #ffffff;
        color: var(--secondary-400);
    }
}

.expand{
    transform: rotate(180deg);
    transition: all 300ms ease;
    color: #8a6cc8;
}

.btn-secondary:hover{
    background-color: #fcfcfc;
    color: var(--secondary-400) !important;
}

.btn-secondary:focus{
    background-color: #f5f5f5;
    color: var(--secondary-400) !important;
    box-shadow: none;
}

.btn-sm{
    padding: 12px 24px;
    font-size: 12px;
}

button{
    padding: 6px;
}

.screens img{
    width: 50%;
}

// badge
span.badge{
    padding: 4px 8px;
    background-color: #f1f1f1;
    border-radius: 20px;
    font-weight: 500;
}

// analysis section

.analysis-section{
    border: 1px solid #efeff3;
    background-color : #f7f7f7;
}

// pricing table
.pricing-table{
    border-radius: 16.32px;
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    min-height: 701px;
    width: 319px;
    border: 1.063px solid var(--Primary-100, #E8E2F4);
    background: var(--Background, #FFF);
    box-shadow: 0px 0.13px 13px 0px rgba(0, 0, 0, 0.10);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover{
        scale: 1.03;
    }
}

.pricing-table-wide{
    border-radius: 16.32px;
    border: 1.063px solid var(--Primary-100, #E8E2F4);
    background: var(--Background, #FFF);
    box-shadow: 0px 0.13px 13px 0px rgba(0, 0, 0, 0.10);
    transition: all 300ms ease-in;
    max-width: 1020px;
}

.scroll-primary,
.scroll-secondary { 
    top: 26%;
    left: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
}

.popular-tag{
    position: absolute;
    top: 0px;
    left: 0px;
    border-top-left-radius: 16.32px;
    border-top-right-radius: 16.32px;
    width: 100%;
    border: 1.063px solid var(--Primary-100, #E8E2F4);
    border-bottom: 0px;
    padding: 2px 16px;
    color: #4CAF50;
}

//Hero section

.hero-text{
    color: #ffffff;
}

.scroll-line{
    transition: all 300ms ease-in-out;
    height: 50px;
    width: 2px;
    background-color: var(--secondary-300);
    position: relative;
    background-image: url(../svgs/hr-line.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0px;
    left: 50%;

    &::after{
        content: '';
        position: absolute;
        height: 50px;
        width: 2px;
        background-color: #ffffff;
        right: 0px;
        top: 0%;
        left: 50%;
        transform: translateX(-50%);
        animation: 1.5s lightening linear infinite;

        @keyframes lightening {
            0%{
                top: -100%;
            }

            15%{
                top: -50%;
            }

            75%{
                top: 75%;
            }

            100%{
                top: 100%;
            }

        }
    }
}


.top-bar{
    transition: all 300ms ease-in;
}

.outline-bottom{
    border-bottom: 1px solid #efeff3;
}

//price switcher


.channel-switcher,
.price-switcher{
    border-radius: 10px;
    padding: 6px;
    background: linear-gradient(0deg, rgba(239, 239, 243, 0.20) 0%, rgba(239, 239, 243, 0.20) 100%), #F6F8FA;
    box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.03) inset;
    

    button{
        display: flex;
        padding: 8px 40px;
        font-size: var(--base-text);
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        transition: all 0.3s ease;
        color: var(--secondary-200);
    }

    button:last-child{
        padding: 10px !important;
    }

    .active{
        background: var(--Background, #FFF);
        box-shadow: 0px 0px 0px 1px #EFEFF3, 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
        color: var(--secondary-500);
        &:hover{
            background: #fff;
            
        }
    }

    button:hover{
        background: #E5E8F0;
    }
}


.channel-switcher{
    background-color: #EFECF9;
    border-radius: 12px;
    border: 1px solid var(--primary-100, #E8E2F4);
    background: #EFECF9;
    width: max-content;

    button:hover{
        background-color: #e7e4f3;
    }
}

.feature-card{
    position: relative;
    width: 100%;
    height: 475px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px #EFEFF3;
    overflow: hidden;
    background-image: url(../svgs/ripple.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: -350%;

    .content{
        position: absolute;
        bottom: 0px;
        z-index: 3;
    }

    .image{
        display: flex;
        flex-direction: column;
        align-items: center;
        width : 100%;
        height: 370px;
        overflow : hidden;

        video{
            width: 70%;
            max-width: 400px;
            margin-top: -53%;
            border-radius: 50px;
        }

        img{
            width: 90%;
            justify-content: end;
            border-radius: 50px;
        }
    }
}

.channel-tab{
    height: 475px;
    width: 90%;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px #EFEFF3;
    overflow: hidden;
}

.url-shortener{
    display: flex;
    width: 330px;
    padding: 10.929px 10.929px 10.85px 10.929px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 9.108px;
    background: #FFF;
    box-shadow: 5.371px 5.371px 21.485px 0px rgba(87, 110, 221, 0.10), -5.371px 5.371px 21.485px 0px rgba(221, 87, 221, 0.10), 0px 0px 0px 0.911px rgba(204, 204, 204, 0.25), 0px 0.911px 1.822px 0px rgba(114, 145, 177, 0.25);

    .link-input{
        border-radius: 5.732px;
        height: 36px;
        font-size: 12.8px;
        overflow: hidden;
        border: 1px solid #E5E5E5;
        padding: 8.189px 9.826px;
        background: #FFF;
        box-shadow: 0px 0.819px 0.819px 0px rgba(0, 0, 0, 0.03);
    }


}

.dittolink{
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.shorten{
    margin-top: -38px;
}

.discount-tag{
    border-radius: 5px !important;
    color: var(--primary-500);
    font-size: var(--label-text);
    background-color: #E5DEF2 !important;
}

.resolution-feature{
    width: 100%;
    flex-shrink: 0;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 0px 1px rgba(204, 204, 204, 0.25), 0px 1px 2px 0px rgba(114, 145, 177, 0.25);
}

.pricing-table span.badge.discount-tag{
    position: absolute;
    top: 28px;
    right: 0px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.flag-toggle{
    border-radius: 12px;
    font-size: var(--small-text);
    cursor: pointer;
    padding: 6px;
    background: #FFF;
    border: 1px solid #efeff3;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.03);

    &:hover{
        background-color: #fcfcfc;
        color: var(--secondary-400) !important;
    }

    &:focus-within{
        background-color: #f5f5f5;
        color: var(--secondary-400) !important;
        box-shadow: none !important;
    }

    &.flat{
        background-color: transparent;
        border: none !important;
        box-shadow: none !important;
    }
}

.gray-hover{ 
    &:hover{
        color: var(--secondary-200) !important;
    }
}

// dropdown & option
.dropdown-list{
    display: block;
    padding: 8px 4px;
    width: 150px;
    cursor: pointer;
    align-items: flex-start;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    z-index: 2;


    .option:not(:last-child){
        margin-bottom: 8px;
    }

    .option{
        display: flex;
        padding: 4px 8px;      
        border-radius: 5px;
        color: var(--secondary-400);

        &:hover{
            background-color: #F6F6F6;
        }
    }

}

.dropdown-overlay{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.03);
    z-index: 1;
}


// form styles

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--secondary-100);
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--secondary-100);
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--secondary-100);
  }

.form-input{
    background-color: #ffffff;
    border: 1.5px solid #e5e5e5;
    border-radius: 7px;
    transition: all 300ms ease-out;
    padding: 12px 24px;
    outline: none;
    cursor: pointer;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
}

.form-input:focus{
    border-color: var(--primary-500) !important;
    background-color: #F9F8FC;
    cursor: auto;
    box-shadow: none;
}

.prepend{
    padding-left: 48px;
}

.input-error{
    border-color: red;
}

.input-icon{
    position: absolute;
    transform: translate(50%, 50%);
}

.accordion{
    border-top: 1px solid var(--primary-100);
    background-color: #ffffff;
    border-bottom: 1px solid var(--primary-100);
    transition: 500ms all ease-in;
}

.mockup{
    img{
        width: 100px;
    }
}

.ussd-content{
    background-image: url(../svgs/box-illustration-fade.svg);
    background-repeat: no-repeat;
    background-position: bottom left;
    height: 350px;
}

.mini-accordion{
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid var(--primary-200);
    transition: all 300ms ease;
    background: linear-gradient(180deg, #E2E2E9 -88.06%, rgba(239, 239, 243, 0.00) 60.47%);

    &:hover{
        background-color: rgba(232, 226, 244, 0.4);
    }

    &:active{
        background-color: rgba(232, 226, 244);
    }

    .show{
        display: block;
    }

}

// Pill

.pill{
    display: flex;
    gap: 6px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    width: max-content;
    padding: 6px 14px;
    background: #FFF;
    box-shadow: 5px 5px 20px 0px rgba(87, 110, 221, 0.10), -5px 5px 20px 0px rgba(221, 87, 221, 0.10), 0px 0px 0px 1px rgba(203, 190, 231, 0.25), 0px 1px 2px 0px rgba(114, 145, 177, 0.25);
}

.plan-comparism-mobile-heading{
    width: 100%;
    position: relative;
    height: 120px;
    overflow: hidden;
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(180deg, #8A6CC8 0%, #6B45BA 100%), #E8E2F4;
    box-shadow: -1px 0px 10px 1px rgba(0, 0, 0, 0.25) inset;
    overflow-y: hidden;

    .illustration{
        position: absolute;
        opacity: .7;
        right: -50px;
        bottom: -20px;
    }
}

.plan-comparism-mobile-table{
    width: 100%;
    position: relative;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(180deg, #8A6CC8 0%, #6B45BA 100%), #E8E2F4;
    box-shadow: -1px 0px 10px 1px rgba(0, 0, 0, 0.25) inset;
    overflow-y: hidden;

    .illustration{
        position: absolute;
        opacity: .7;
        right: -50px;
        bottom: -20px;
    }
}

.rotate{
    transform: rotate(90deg);
}

.plan-comparism{
    width: 1020px;
    position: relative;
    height: 773px;
    overflow: hidden;
    border-radius: 30px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(180deg, #8A6CC8 0%, #6B45BA 100%), #E8E2F4;
    box-shadow: -1px 0px 10px 1px rgba(0, 0, 0, 0.25) inset;
    overflow-y: hidden;


    .compare-table{
        height: 85%;
        overflow-y: scroll;
    }

    tbody tr td,
    table thead th{
        padding: 14px;
        // border-radius: 5px;
        width: max-content;
        color: #ffffff;
    }

    tbody tr td{
        color: var(--primary-200);
        text-align: center;
    }

    tbody tr{
        transition: all 300ms ease-in;
        
        &:hover{
            border-radius: 5px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) -30.07%, rgba(255, 255, 255, 0.00) 183.1%);
        }
    }

    thead{
        border-radius: 5px;
        background: #8A6CC8;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
    }

    .desc-col{
        width: 50%;
    }

    .illustration{
        position: absolute;
        opacity: .7;
        right: -50px;
        bottom: -20px;
    }

}

// socials

.social-icon{
    transition: all 300ms ease-in;

    &:hover{
        transform: scale(1.2) rotate(-1deg);
    }

}

.jumbotron{
    background-image: url(../svgs/jumbotron-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.section-one{
    background-image: url(../svgs/hexagon-bg.svg);
    background-repeat: no-repeat;
    background-position-x: 50rem;
    background-size: 50%;
    background-position-y: -10px;
    overflow: visible;
}

.section-three{
    background-image: url(../svgs/hexagon-bg.svg);
    background-repeat: no-repeat;
    background-position-x: 0rem;
    background-size: 50%;
    background-position-y: 30rem;
    overflow: visible;
}

.analysis-section{
    background-image: url(../svgs/box-illustration-bw.svg);
    background-repeat: no-repeat;
    max-height: 608px;
    overflow: hidden !important;
    background-size: 50%;
    background-position-y: bottom;
    background-position-y: 25rem;
    overflow: visible;
}

.client{
    max-height: 54px;
    filter: grayscale(1);
    transition: all .5s linear;

    &:hover{
        filter: grayscale(0);
    }
}

.clients{
    background-image: url(../svgs/hr-line.svg);
    background-repeat: no-repeat;
    background-position-y: 25rem;
    overflow: visible;
}

.hrline{
    transition: all 300ms ease-in-out;
    height: 100px;
    position: relative;
    background-image: url(../svgs/hr-line.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    overflow: hidden;

    &::after{
        content: '';
        position: absolute;
        height: 2px;
        width: 100px;
        right: 0px;
        top: 20%;
        transform: translateY(-20%);
        animation: 10s meteor linear infinite;
        
        background: linear-gradient(90deg, #8A6CC8 10%, rgba(226, 219, 242, 0.00) 70.05%);

        &:hover{
            right: 100px;
        }

        @keyframes meteor {
            0%{
                right: 0%;
            }

            25%{
                right: 25%;
            }

            50%{
                right: 50%;
            }

            75%{
                right: 75%;
            }

            100%{
                right: 100%;
            }

        }
    }
}


.hrline-alt{
    transition: all 300ms ease-in-out;
    height: 100px;
    position: relative;
    background-image: url(../svgs/hr-line.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    overflow: hidden;

    &::after{
        content: '';
        position: absolute;
        height: 2px;
        width: 100px;
        left: 0px;
        top: 20%;
        transform: translateY(-20%);
        animation: 10s meteor-alt linear infinite;
        
        background: linear-gradient(-90deg, #8A6CC8 10%, rgba(226, 219, 242, 0.00) 70.05%);

        @keyframes meteor-alt {
            0%{
                left: 0%;
            }

            25%{
                left: 25%;
            }

            50%{
                left: 50%;
            }

            75%{
                left: 75%;
            }

            100%{
                left: 100%;
            }
        }
    }
}


.testimony{
    display: flex;
    padding: 16px;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    background: #FFF;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 5px 5px 28px 0px rgba(87, 110, 221, 0.10), -5px 5px 28px 0px rgba(221, 87, 221, 0.10), 0px 2.669px 2.669px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1.335px #EFEFF3;

    &:hover{
        scale: 1.03;
    }
}

.jumbotron-cover{
    background-image: url(../svgs/hexagon-bg.svg);
    background-repeat: no-repeat;
    background-position-x: 0rem;
    background-size: 50%;
    background-position-y: -100rem;
    overflow: visible !important;
}

.pfp{
    height: 50px;
    width: 50px;
    border-radius: 100px;
    background-color: #EFEFF3;
}

.engagement-section{
    background-image: url(../svgs/box-illustration-fade.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
}

.resolute-texts{
    height: 320px;
    background-image: url(../svgs/box-illustration-fade.svg);
    background-repeat: no-repeat; 
    overflow: visible;
    background-position: bottom right;
}



.engagement-tab{
    display: flex;
    flex-direction: column;
    gap: 32px;
    font-size: 12.8px;
    

    button{
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        background-color: #ffffff;
        color: var(--secondary-300);
        width: max-content;
        text-wrap: nowrap;
        transition: all 300ms ease-out;


        &.active{
            background-color: var(--primary-100) !important;
            color: var(--primary-500);
        }

        &:hover{
            background-color: #f8f8f8;
        }
    }
}

.section-four{
    border-radius: 16px;
    border: 1px solid var(--Primary-100, #E8E2F4);
    background: #FAF9FD;
}

.section-six{
    background-image: url(../svgs/hexagon-bg.svg);
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-size: 50%;
    background-position-y: -20rem;
    overflow: visible;
}

.section-seven{
    background-image: url(../svgs/hexagon-bg.svg);
    background-repeat: no-repeat;
    background-position-x: 50rem;
    background-size: 100%;
    background-position-y: -10px;
    overflow: visible;
}

.modal-footer{
    background-image: url(../svgs/GroupOfIcons02.svg);
    background-repeat: no-repeat;
    background-position-x: -40px;
}
.side-graphics{
    background: linear-gradient(180deg, #8A6CC8 0%, #624B93 100%);
}
.mobile-hexagon-bg,
.side-hexagon{
    background-image: url(../svgs/hexagon-side.svg);
    background-repeat: no-repeat;
}
.bg-group-icon{
    background-image: url(../svgs/GroupOfIcons.svg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-x: -40px;
}

.aviation-video{
    background:  linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 50%), linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 50%), url(../images/gifs/pilot.gif);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-position-x: center;
    width : 100%;
    height : 100dvh;
}

.banking-video{
    background:  linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 50%), linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 50%), url(../images/gifs/banking.gif);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-position-x: center;
    width : 100%;
    height : 100dvh;
}

.healthcare-video{
    background:  linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 50%), linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 50%), url(../images/gifs/man+with+doctor.gif);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-position-x: center;
    width : 100%;
    height : 100dvh;
}

.video-nav{
    z-index: -23;
}

.progress-bar{
    transition: all 300ms ease;
    background-color: #4C4C4C;
}

.progress-bar::after{
    content: '';
    height: 4px;
    width: 30%;
    margin: 0px 4px;
    background-color: #ffffff;
    border-radius: 100px;

}

.error-desc{
    border-radius: 20px;
    border: 1px solid var(--Secondary-100, #CCC);
    background: #FCFCFC;
    padding: 8px 16px;
}

.demo-video{
    background-image: url(../images/browser.png);
    background-repeat: no-repeat;
    background-size: 80%;
    background-position-x: center;
    background-position-y: center;
    height: 750px;
    transition: all 300ms linear;
    position: relative;
    cursor: pointer;

    &::before{
        background-image: url(../images/browser.png);
        content: '';
    }

    &::after{
        content:'';
        height: 100%;
        border-radius: 20px;
        top: 0px;
        left: 0px;
        background: rgba(138, 108, 200, 0.40);
        width: 100%;
        position: absolute;
        transition: all 300ms linear;
    }

    &:hover{
        background-size: 90%;

        &::after{
            background: rgba(138, 108, 200, 0.90);
        }
    }

    img{
        z-index: 8000;
    }
}

.popup{
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100vw !important;
    backdrop-filter: blur(10px);
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.764);
    opacity: 50;

}

.main-video{
    position:absolute;
    top: 50%;
    left: 50%;
    border-radius: 20px;
    transform: translate(-50%, -50%);

    button{
        background-color: #ffffffd8;
        border-radius: 50px;
        padding: 8px 14px;
        transition: all 300ms ease;

        &:hover{
            background-color: #ffffff;
        }
    }
}

.overlay-video{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 640px) { 

    .channel-tab.whatsapp{
        height: 55rem;

        video{
            margin-top: 2rem;
        }
    }

    .channel-tab{
        max-width: 95%;
    }

    .mockup img{
        margin-top: -15rem;
    }

    .url-shortener{
        margin-top: 48px !important;
        margin: 8px;
    }

    .heading-five{
        font-size: var(--base-text);
    }

    .engagement-tab {

        button{
            padding: 8px 24px;
            justify-content: center;
            width: 100%;
        }

        img{display: none !important;}
    }

    .hrline, .hrline-alt{
        display: none !important;
    }

    .analyse-dashboard{
        margin-top: 0rem !important;        
    }

    .mockup02{
            width: 100% !important;
        }

    .heading-four{
        font-size: var(--heading-six);
    }

    .channel-switcher button{
        font-size: 10.24px;
        padding: 8px 12px;
    }

    img.client{
        width: 130px;
    }

    .engagement-tab{
        flex-direction: row;
        justify-content: center;
    }
    
    .btn{
        padding-left: 24px;
        padding-right: 24px;
    }

    .heading-three{
        font-size: var(--heading-five);
    }
    .base-text{
        font-size: var(--small-text);
    }
    .heading-two{
        font-size: var(--heading-five);
    }
    .heading-six.desc{
        font-size: var(--base-text);
    }
    .heading-five.satisfaction{
        font-size: var(--base-text);
    }
    .btn-lg{
        padding: 12px 24px
    }
    .heading-two.text-left{
        font-size: var(--heading-four);
    }
    .healthcare-video,
    .aviation-video,
    .banking-video{
        max-width: 100dvw;
        margin-right: 0px;
        max-height: 100dvh;
    }

    .hero-section{
        max-width: 100% !important;
    }

    .scroll-line{
        left: 50%;
        transform: translateX(-50%);
    }

    .pricing-table{
        max-width: 100%;
        width: 100%;
    }

    .jumbotron{
        background-attachment: scroll;
    }

    .demo-video{
        max-height: 200px;

        &::after{
            background: rgba(138, 108, 200, 0.90);
        }
    }

    .overlay-video{
        top: 65%;
        img{
            width: 32px;
        }
    }

    .main-video{
        iframe{
            width: 375px;
            
        }
    }

}

@media (min-width: 931px) {
    .top-bar-menu{
        display: none !important;
    }

    .about-texts{
        width: 100% !important;
    }
}

@media (min-width: 1440px) {
    #hero-heading{
        font-size: 62px;
    }
}


@media (max-width: 930px){
    .top-bar-links{
        display: none !important;
    }
    .top-bar-menu{
        display: flex !important;
    }
    
}

@media (min-screen: 769px){
    .about-texts{
        width: 100% !important;
    }
}

@media (max-width: 768px) {

    .flag-toggle{
        color: var(--secondary-500);
    }

    #freetrial{
        display: none;
    }

    #signin.btn-alt{
        background-color: #ffffff;
        color: var(--secondary-300);
        border: 1.5px solid #e5e5e5;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
    }

    .heading-two{
        font-size: var(--heading-four);
    }
    .heading-three{
        font-size: var(--heading-five);
    }
    .heading-six{
        font-size: var(--base-text);
    }

    .pricing-table{
        max-width: 100%;
        width: 100%;
    }

    .jumbotron{
        background-attachment: scroll;
    }
}

@media (max-width: 1024px) {

    
    .heading-two{
        font-size: var(--heading-four);
    }
    .heading-three{
        font-size: var(--heading-five);
    }
    .heading-six{
        font-size: var(--base-text);
    }



    .mockup01{
        max-width: 640px !important;
    }

    .mockup04,
    .mockup02{
        max-width: 400px !important;
    }

    .mockup03{
        max-width: 600px !important;
    }
}

/* Maximum height */
@media (max-height: 650px) {
    .md\:h-5\/6{
        height: 100% !important;
    }

  }


@media (max-height: 584px) {
    .hero-heading{
        font-size: var(--heading-four) !important
    }

    .stars{
        margin-top: 3rem;
    }

    .main-video{
        iframe{
            height: 400px !important;
        }
    }

    
}